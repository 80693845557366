.site-footer {
  border-top: 1px solid #505050;
  z-index: 9;
  position: relative;
}

.site-footer--transparent {
  border-top: none;
  background-color: transparent;
}

.site-footer__copyright {
  font-size: 9.8px;
  font-weight: 200;
  color: white;

  @screen xl {
    font-size: 13.4px;
  }
}

.site-footer__copyright--black {
  color: black;
}
