.select-control__control {
  padding: 2px;
  background-color: #262d36 !important;
  cursor: pointer !important;

  @screen md {
    padding: 3px;
  }
}

.select-control-light .select-control__control {
  background-color: white !important;
}

.select-control-light .select-control__indicator {
  color: black !important;
}

.select-control__control--is-focused {
  border-color: #cccccc !important;
  box-shadow: none !important;
}

.select-control__input {
  color: #9ca0aa !important;
  font-size: 14px !important;

  @screen md {
    font-size: 16px !important;
  }
}

.select-control__value-container {
  padding-left: 6px !important;
}

.select-control-light .select-control__single-value {
  color: black !important;
}

.select-control__single-value {
  font-size: 14px !important;
  color: #9ca0aa !important;
  margin-left: 3px !important;

  @screen md {
    font-size: 16px !important;
  }
}

.select-control__menu {
  background-color: #262d36 !important;
  border: 1px solid #cccccc !important;
  z-index: 99 !important;
}

.select-control-light .select-control__menu {
  background-color: white !important;
}

.select-control__option {
  font-size: 14px !important;
  color: #9ca0aa !important;

  @screen md {
    font-size: 16px !important;
  }
}

.select-control-light .select-control__option {
  color: black !important;
}

.select-control__option--is-focused {
  color: white !important;
  background-color: #37404c !important;
}

.select-control-light .select-control__option--is-focused {
  color: white !important;
}

.select-control__option--is-selected {
  color: white !important;
  background-color: #6bc581 !important;
}
