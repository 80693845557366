.navbar {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 999;
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid transparent;
  height: 54px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: -1;
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid transparent;

    @screen lg {
      height: 100px;
    }

    @screen xl {
      height: 115px;
    }
  }
}

.navbar .logo {
  margin-top: -6px;
  transition: all 0.3s ease-in-out;
}

.navbar_secondary {
  position: relative;
  top: 30px;
}

.navbar_sec {
  top: auto;
}

.container {
  display: flex;
  position: absolute;
  z-index: 9;
  justify-content: space-between;
  left: 0;
  right: 0;
  top: 0;
  align-items: center;
  transform: translateY(15px);
  transition: all 0.3s ease-in-out;

  @screen xl {
    transform: translateY(31px);
  }
}

.navbar__pinned {
  position: fixed;
  transition: all 0.3s ease-in-out;

  &:after {
    border-bottom: 1px solid #303030;
    background-color: rgba(black, 0.3);
    backdrop-filter: blur(8px);
    transform: translateY(-20px);
    transition: all 0.3s ease-in-out;

    @screen xl {
      transform: translateY(-30px);
    }
  }
}

.navbar__pinned_sec {
  top: 40px;
}

.navbar__pinned_light {
  &:after {
    border-bottom: 1px solid #d5d5d5;
    background-color: rgba(white, 0.3) !important;
  }
}

@-moz-document url-prefix() {
  .navbar__pinned {
    &:after {
      background-color: rgba(black, 0.85);
    }
  }
}

.navbar__pinned .container {
  transform: translateY(0);
  transition: all 0.3s ease-in-out;

  @screen lg {
    transform: translateY(14px);
  }
}

.navbar__pinned .nav_sec_offset {
  transform: translateY(0);
  transition: all 0.3s ease-in-out;

  @screen lg {
    transform: translateY(14px);
  }

  @screen xl {
    transform: translateY(24px);
  }
}

.navbar__pinned .navbar_mini {
  @screen xl {
    transform: translateY(18px);
  }
}

.navbar__pinned .logo {
  transform: scale(0.6);
  transform-origin: left;
  transition: all 0.3s ease-in-out;

  @screen lg {
    transform: scale(0.9);
  }

  @screen xxl {
    transform: scale(1);
  }
}
