.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: white;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 8px;

  @screen lg {
    padding-left: 90px;
    padding-right: 80px;
  }

  &.show {
    transform: none;
  }
}

.fade {
  transition: opacity 0.15s linear;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(black, 0.6);
  backdrop-filter: blur(8px);

  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: 1;
  }
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 90%;
  transform: translateX(100%);
  max-width: 900px;

  @screen lg {
    width: 80%;
  }

  @screen larger {
    width: 70%;
  }

  @screen 3xl {
    width: 50%;
  }

  &.show {
    transform: none;
  }
}

.offcanvas--hard-science.offcanvas-end {
  width: 90%;
  max-width: 1300px;

  @screen xxl {
    width: 70%;
  }
}

.offcanvas--mobile-menu.offcanvas-end {
  max-width: 400px;

  @screen lg {
    max-width: 600px;
  }

  @screen xl {
    max-width: 800px;
  }
}

.offcanvas-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 24px;
}

.offcanvas-body {
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 30px;
  padding-right: 24px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: fixed;
    width: 100%;
    height: 30px;
    z-index: 99;
    background-image: linear-gradient(
      180deg,
      white 0%,
      rgba(255, 255, 255, 0.001) 100%
    );
  }
}
