.control_phone {
  font-family: 'Manrope';
  position: relative;
  margin-bottom: clamp(16px, 1.5vw, 22px);
}

.control_phone_label_dark {
  color: white;
  font-weight: 600;
  font-size: 13px;
  display: inline-block;
  margin-bottom: clamp(5px, 1vw, 10px);

  @screen md {
    font-size: 16px;
  }
}

.control_phone_label_dark.white_control {
  color: black;
}

.PhoneInput {
  display: flex;
  align-items: center;
  height: 41.5px;
  width: 100%;
  padding: 10px 12px;
  color: black;
  border: 1px solid #cccccc;
  background: #262d36;
  border-radius: 4px;
  font-size: 13px;
  outline: none;

  @screen md {
    font-size: 16px;
    height: 46px;
  }
}

.PhoneInput.white_control {
  background: white;
}

.PhoneInputCountry {
  position: relative;
  display: flex;
  align-items: center;
}

.PhoneInputInternationalIconGlobe {
  stroke: white;
}

.PhoneInputInternationalIconPhone {
  fill: white;
}

.PhoneInputCountrySelect {
  height: 40px;
  width: 40px;
  position: relative;
  z-index: 9;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountryIcon {
  width: 30px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.PhoneInputInput {
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: #9ca3af;
  margin-left: 6px;
  outline: none;

  &:focus {
    caret-color: #5ad082;
  }
}

.PhoneInputCountrySelectArrow {
  display: flex;
  align-items: center;
  width: 10px;
  height: 8px;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 6px;
    height: 1px;
    background-color: #cccccc;
    position: absolute;
    transform: rotate(-45deg);
    right: 0;
  }

  &:before {
    content: '';
    display: block;
    width: 6px;
    height: 1px;
    background-color: #cccccc;
    position: absolute;
    transform: rotate(45deg);
    left: 0;
  }
}

/* Change the white to any color */
.PhoneInputInput:-webkit-autofill,
.PhoneInputInput:-webkit-autofill:hover,
.PhoneInputInput:-webkit-autofill:focus,
.PhoneInputInput :-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #262d36 inset !important;
  -webkit-text-fill-color: #9ca0aa !important;
}

.white_control .PhoneInputInput:-webkit-autofill,
.white_control .PhoneInputInput:-webkit-autofill:hover,
.white_control .PhoneInputInput:-webkit-autofill:focus,
.white_control .PhoneInputInput :-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: black !important;
}

.white_control .PhoneInputInput {
  color: black;
}

.control_phone_error {
  position: absolute;
  left: 0;
  font-size: 10px;
  color: red;
  letter-spacing: 0.5px;
  line-height: 1;
  margin-top: 4px;

  @screen md {
    font-size: 12px;
  }
}

.control_phone_invalid {
  border: 1px solid red;
}

.control_phone_valid {
  border: 1px solid #4fbb6a;
}

.control_phone_invalid .PhoneInputInput,
.control_phone_valid .PhoneInputInput {
  padding-right: 30px;
}

.control_phone_icon {
  position: absolute;
  right: 16px;
  bottom: 11px;
  margin: auto;
  height: 20px;
  width: 20px;

  @screen md {
    bottom: 13px;
  }
}
