.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 5px;
  z-index: 999;
  cursor: pointer;

  @screen larger {
    display: none;
  }

  span {
    display: block;
    height: 2px;
    width: 40px;
    background-color: white;

    &:last-child {
      width: 25px;
      margin-left: auto;
      margin-top: 9px;
    }
  }
}

.menu_small {
  span {
    width: 30px;

    &:last-child {
      width: 20px;
    }
  }
}

.menu_visible {
  display: block !important;
  width: auto;
  height: auto;
}
