.discount_badge_wrapper {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;

  @screen smaller {
    max-width: 370px;
  }
}

.discount_badge {
  position: relative;
  padding: 30px;
  margin: 26px;
  text-align: center;
  background-color: #bca36b;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.discount_badge_close {
  position: absolute !important;
  top: 5px;
  right: 5px;
  transform: scale(0.6);
}
