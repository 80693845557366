.tabs {
}

.tabs__button {
  height: 40px;
  border-top: 1px solid #58616b;
  border-bottom: 1px solid #58616b;
  cursor: pointer;

  &:first-child {
    border-right: 1px solid #58616b;
  }
}

.tabs__button--light {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;

  &:first-child {
    border-right: 1px solid #eeeeee;
  }
}

.tabs__button-active {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $secondary;
  }
}
