.cookie_consent {
  position: fixed;
  opacity: 0;
  padding: 10px;
  padding-bottom: 0;
  max-width: 1080px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-image: linear-gradient(
    90deg,
    #183358 0%,
    #284374 50%,
    #183358 100%
  );
  z-index: 9999;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: 0 -5px 10px rgba(black, 0.1);
  transition: transform 0.3s ease;

  @screen sm {
    padding: 20px;
    padding-bottom: 0;
  }
}

.cookie_consent_header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.cookie_consent_footer {
  border-top: 1px solid #3f5d87;
}

.cookie_consent_body {
  margin-top: 20px;
  border-top: 1px solid #3f5d87;
  padding-top: 20px;
  max-height: 500px;
  padding-right: 10px;
  overflow: scroll;

  @media (max-height: 700px) {
    max-height: 400px;
  }

  @media (min-height: 800px) {
    max-height: none;
    padding-right: 0;
    overflow: unset;
  }
}

.accordion_header {
  display: flex;
  justify-content: space-between;
  padding-left: 26px;
  padding-top: 20px;
  padding-bottom: 14px;
  border-top: 1px solid #3f5d87;
}

.accordion_body {
  padding-left: 26px;
  padding-bottom: 20px;
}
