.cross_btn {
  width: 40px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cross_btn:hover .cross_btn_item {
  transform: rotate(180deg);
  transition: transform 0.2s ease;
}

.cross_btn_item {
  width: 30px;
  height: 2px;
  position: absolute;
  transition: transform 0.2s ease;

  &:first-child {
    transform: rotate(45deg);
  }

  &:last-child {
    transform: rotate(-45deg);
  }
}

.cross_btn_item_black {
  background-color: black;
}

.cross_btn_item_white {
  background-color: white;
}

.cross_btn_item_gold {
  background-color: #bca36b;
}
