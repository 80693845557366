.nav__wrapper {
  display: none;

  @screen larger {
    display: flex;
    align-items: center;
  }
}

.nav__wrapper--mini {
  display: flex;
  align-items: center;
}

.nav--primary {
  display: flex;
}

.nav--secondary {
  @screen larger {
    display: flex;
  }
}

.nav__item {
  font-weight: 600;
  font-family: $font-secondary;
  letter-spacing: 0.35px;
}

.nav--submenu-hover {
  &:hover {
    .chevron {
      transform: rotate(180deg);
      transition: all 0.3s ease;
    }

    & > div {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      transition: all 0.3s ease;
    }
  }
}

.submenu-anim {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.3s ease;
}

.nav__item--primary {
  margin-right: clamp(30px, 3vw, 50px);
}

.nav__item--margin {
  margin-right: 30px;
}

.nav__item--offcanvas {
  color: black;
}

.nav__item--secondary {
  font-size: 9px;
  margin-bottom: 18px;
  position: relative;
  cursor: pointer;

  .arrow-right {
    display: none;

    @screen sm2 {
      display: block;
      transform: scale(0.5);
      transform-origin: right;
    }

    @screen lg {
      display: none;
    }
  }

  .dot {
    display: none;

    @screen lg {
      display: block;
      position: absolute;
      right: -16px;
    }

    @screen larger {
      right: -22px;
    }

    @screen 3xl {
      right: -32px;
    }
  }

  @screen lg {
    font-size: clamp(11px, 1vw, 18px);
    margin-bottom: 0;
    margin-left: 30px;
  }

  @screen larger {
    margin-left: 40px;
  }

  @screen 3xl {
    margin-left: 60px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  a {
    display: flex;
    align-items: center;
  }
}

.nav__item--mobile {
  font-size: clamp(20px, 3vw, 28px);
  margin-bottom: 18px;
  margin-left: 0;
}

.arrow-right {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 2px;
  margin-right: 20px;
  background-color: white;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: -5px;
    display: block;
    width: 12px;
    height: 12px;
    border-top: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
  }
}

.arrow-right--black {
  background-color: black;

  &:after {
    border-color: black;
  }
}
