.fade-up-enter {
  transform: translateY(100%);
  transition: all 0.5s ease;
}

.fade-up-enter-active {
  transform: translateY(0);
  transition: all 0.5s ease;
}

.fade-up-exit {
  transform: scale(1);
  opacity: 1;
}

.fade-up-exit-active {
  transform: scale(0.5);
  opacity: 0;
  transition: all 0.5s ease;
}
