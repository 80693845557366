.button {
  display: flex;
  flex-shrink: 0;
  height: 50px;
  padding: 6px 32px;
  border-radius: 50px;
  align-items: center;
  font-family: Manrope;
  color: white;
  font-size: clamp(15px, 1vw, 18px);
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 1;
  filter: grayscale(0);
  transition: filter 0.6s ease;
  cursor: pointer;
}

.button:disabled {
  filter: grayscale(1);
  transition: filter 0.6s ease;
}

.button:hover .button__arrow {
  animation-name: move_arrow;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
}

.button--sm-height {
  height: 38px;
}

.button--lg-height {
  height: 60px;
}

.button--link {
  display: inline-block;
}

.button--sm-pad {
  padding: 6px 22px;
}

.button--primary {
  background-size: 400% 100%;
  transition: all 0.2s ease-in-out;
  background-image: linear-gradient(
    89.95deg,
    #be55f0 0%,
    #2e6ef3 12%,
    #5ad082 25%,
    #5ad082 50%,
    #2e6ef3 120%
  );
}

.button--primary:hover {
  background-position: 100% 0;
  box-shadow: 0 2px 15px 0 rgba(white, 0.2);
  transition: all 0.2s ease-in-out;
}

.button--black {
  background-color: black;
  font-size: 14px;
  transition: all 0.4s ease;
}

.button--white {
  background-color: white;
  color: black;
  font-size: 14px;
  transition: all 0.4s ease;
}

.button--white:hover {
  background-color: black;
  color: white;
  transition: all 0.4s ease;
}

.button--secondary {
  border: 1px solid $secondary;
  color: $secondary;
  transition: all 0.4s ease;

  .button__arrow {
    background-color: $secondary;

    &:after,
    &:before {
      background-color: $secondary;
    }
  }
}

.button--third {
  background-image: linear-gradient(
    90deg,
    #d0bd91 0%,
    #d0bd91 71%,
    #d0bd91 100%
  );
  font-size: 16px;
  box-shadow: 0 5px 8px rgba(#bdab82, 0.5);
  transition: all 0.3s ease;

  .icon {
    transition: all 0.3s ease;
  }

  &:hover {
    box-shadow: 0 0px 8px rgba(#bdab82, 0.5);
    transition: all 0.3s ease;

    .icon {
      transform: scale(1.1);
      transition: all 0.3s ease;
    }
  }
}

.button--secondary-white {
  border: 1px solid white;
  color: white;
  transition: all 0.4s ease;

  .button__arrow {
    background-color: white;

    &:after,
    &:before {
      background-color: white;
    }
  }
}

.button--secondary:hover {
  background-color: $secondary !important;
  transition: all 0.4s ease;
  color: $primary;

  .button__arrow {
    background-color: $primary;
    transition: all 0.4s ease;

    &:after,
    &:before {
      background-color: $primary;
      transition: all 0.4s ease;
    }
  }
}

.button--border {
  position: relative;
  background-size: 400% 100%;
  transition: all 0.2s ease-in-out;
  background-image: linear-gradient(
    89.95deg,
    #be55f0 0%,
    #2e6ef3 12%,
    #5ad082 25%,
    #5ad082 50%,
    #2e6ef3 120%
  );

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background-color: #213257;
    border-radius: 50px;
    z-index: -1;
  }
}

.button--border-white {
  position: relative;
  color: black;
  background-size: 400% 100%;
  transition: all 0.2s ease-in-out;
  background-image: linear-gradient(
    89.95deg,
    #be55f0 0%,
    #2e6ef3 12%,
    #5ad082 25%,
    #5ad082 50%,
    #2e6ef3 120%
  );

  &:hover {
    color: white;

    &:after {
      opacity: 0;
      transition: all 0.3s ease;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background-color: white;
    border-radius: 50px;
    z-index: -1;
    transition: all 0.3s ease;
  }
}

.button--border-bg-sec {
  &:after {
    background-color: #141b24;
  }
}

.button--border:hover .button__arrow {
  animation-name: move_arrow_back;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
}

.button--secondary-white:hover {
  background-color: white;
  transition: all 0.4s ease;
  color: black;

  .button__arrow {
    background-color: white;
    transition: all 0.4s ease;

    &:after,
    &:before {
      background-color: white;
      transition: all 0.4s ease;
    }
  }
}

.button__arrow {
  height: 1px;
  width: 50px;
  margin-left: 28px;
  position: relative;
  background-color: white;
  transition: all 0.4s ease;

  &:after,
  &:before {
    content: '';
    display: block;
    height: 1px;
    width: 5px;
    position: absolute;
    right: 0;
    background-color: white;
    transition: all 0.4s ease;
  }

  &:after {
    transform: rotate(45deg);
    top: -2px;
  }

  &:before {
    transform: rotate(-45deg);
    bottom: -2px;
  }
}

.button__chevron {
  width: 0;
  background-color: transparent;

  &:after,
  &:before {
    width: 8px;
  }

  &:after {
    transform: rotate(45deg);
    top: -3px;
  }

  &:before {
    transform: rotate(-45deg);
    bottom: -3px;
  }
}

.button__arrow_small {
  width: 30px;
  margin-left: 8px;
}

.button__arrow_sec {
  margin-left: 40px;
  animation-name: bounce;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;

  span {
    display: block;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid white;
    border-left: 2px solid white;
    transform: rotate(-45deg);
  }

  span:last-child {
    margin-top: -3px;
  }
}

.button__arrow-back {
  transform: rotate(180deg);
}

.button__arrow--green {
  background-color: #5ad082;

  &:after {
    background-color: #5ad082;
  }

  &:before {
    background-color: #5ad082;
  }
}

.button__arrow--black {
  background-color: black;

  &:after {
    background-color: black;
  }

  &:before {
    background-color: black;
  }
}

.button__arrow--gold {
  background-color: #bca36b;

  &:after {
    background-color: #bca36b;
  }

  &:before {
    background-color: #bca36b;
  }
}

.button--max-w {
  justify-content: space-between;

  @screen md {
    width: 100%;
  }
}

.button--bold-font {
  font-weight: 800;
}

.button--full-width {
  width: 100%;
  justify-content: space-between;
}

@keyframes move_arrow {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes move_arrow_back {
  0% {
    transform: translateX(0px) rotate(180deg);
  }
  50% {
    transform: translateX(5px) rotate(180deg);
  }
  100% {
    transform: translateX(0px) rotate(180deg);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-10px);
  }
}
