@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/rnCr-xNNww_2s0amA9M5knjsS_ul.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/rnCs-xNNww_2s0amA9uSsG3BafaPWnII.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Spectral';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/rnCu-xNNww_2s0amA9M8qonFWfSFXVAKArc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/Spectral-Bold.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Spectral';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/rnCu-xNNww_2s0amA9M8qrXHWfSFXVAKArc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400 800;
  font-display: swap;
  src: url(/fonts/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

html {
  --animationSpeed: 10s;
  --tapeOffset: calc(-360px * 4);
}

body {
  background-color: $primary;
  font-family: 'Spectral', serif;
  font-weight: 400;
  color: #f6f4ee;
}

::-moz-selection {
  color: white;
  background: #be55f0;
}

::selection {
  color: white;
  background: #be55f0;
}

.gradient-bg {
  background-image: linear-gradient(
    90deg,
    #183358 0%,
    #345288 50%,
    #183358 100%
  );
}

.gradient-black-bg {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.color--black {
  color: #19232f;
}

.color--white {
  color: white;
}

.color--green {
  color: #5ad082;
}

.color--gray {
  color: #5e5e5e;
}

.bg--white {
  background-color: white;
}

.bg-purple {
  background-color: #bc55f0;
}

.bg--dark-blue {
  background-color: #202a36;
}

.bg--dark-dark-blue {
  background-color: #0f1115;
}

.bg-blue-gray {
  background-color: #212d3a;
}

.bg-blue {
  background-color: #376cf2;
}

.bg-aqua {
  background-color: #f4f6f9;
}

.top-40px {
  top: 40px;
}

.absolute-bg-dark-blue {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    background-color: #111b27;
    width: 150%;
    height: 100%;
    top: 0;
    left: -60px;
    z-index: -1;

    @screen sm2 {
      display: none;
    }
  }
}

.bg--dark-blue-2 {
  background-color: #111b27;
}

.bg--light-blue-2 {
  background-color: #1b2633;
}

.bg--gray-light {
  background-color: #f5f5f5;
}

.bg--dark-gray {
  background-color: #262d36;
}

.bg--gray {
  background-color: #e4e4e4;
}

.bg--light-brown {
  background-color: #fefaf0;
}

.bg--brown {
  background-color: #bca36b;
}

.bg--gray-light-important {
  background-color: #f5f5f5 !important;
}

.bg--F8F1E2 {
  background-color: #f8f1e2;
}

.color-gray {
  color: #bbbbbb;
}

.color-red {
  color: red;
}

.color-purple {
  color: #ba55ec;
}

.color-secondary {
  color: $secondary;
}

.color-F2E5C9 {
  color: #f2e5c9;
}

.font--primary {
  font-family: 'Spectral', serif;
}

.font--accent {
  font-family: 'Manrope';
}

.text-wide {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 3px;
}

.box-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
}

.border-gray {
  border: 1px solid #cccccc;
}

.border-dark-gray {
  border: 1px solid #5f5e5e;
}

.border-gray-sec {
  border: 1px solid #72767c;

  @screen lg {
    border: none;
  }
}

.wistia_responsive_padding {
  border-radius: 5px;
  overflow: hidden;

  video {
    border-radius: 5px;
  }
}

.fullscreen-page {
  height: 100vh;
  min-height: 640px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.free-call-carousel-bullets {
  position: relative;
  margin-top: 20px !important;
}

.hover-bg-secondary {
  &:hover {
    background-color: #f5eede;
  }
}

.track-vertical {
  top: 2px;
  bottom: 2px;
  right: 2px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.2);
}

.thumb-vertical {
  position: relative;
  display: block;
  width: 8px !important;
  left: -1px;
  cursor: pointer;
  border-radius: inherit;
  background-color: #19232f;
}

.videos-list-left-gradient {
  position: absolute;
  top: 1px;
  left: 0;
  width: 80px;
  height: calc(100% - 2px);
  background-image: linear-gradient(
    90deg,
    rgba(24, 51, 88, 1) 0%,
    rgba(24, 51, 88, 1) 40%,
    rgba(24, 51, 88, 0) 100%
  );
  pointer-events: none;
  z-index: 9;
  opacity: 0;
  transition: all 0.2s ease;

  @screen sm2 {
    width: 120px;
  }

  @screen md {
    left: -60px;
  }
}

.videos-list-right-gradient {
  position: absolute;
  top: 1px;
  right: 0;
  width: 80px;
  height: calc(100% - 2px);
  background-image: linear-gradient(
    270deg,
    rgba(24, 51, 88, 1) 0%,
    rgba(24, 51, 88, 1) 40%,
    rgba(24, 51, 88, 0) 100%
  );
  pointer-events: none;
  z-index: 9;
  opacity: 0;
  transition: all 0.2s ease;

  @screen sm2 {
    width: 120px;
  }

  @screen md {
    right: -60px;
  }
}

.banner {
  text-align: center;
  background-color: #bda36a;
  color: black;
  font-family: Manrope;
  font-size: 10px;
  font-weight: 600;
  opacity: 1;
  height: 43px;
  transition: all 0.2s ease;

  @screen sm {
    font-size: 10px;
  }

  @screen md {
    font-size: 13px;
  }
}

.banner--unpinned {
  position: fixed;
  width: 100%;
  z-index: 99;
  transform: translateY(-100%);
}

.banner--black {
  background-color: black;
}

.banner--pinned {
  position: fixed;
  width: 100%;
  z-index: 99;
  transform: translateY(0);
}

.trustpilot-custom {
  /* font-size: 8px; */
}

.videos-list-right-gradient--active {
  opacity: 1;
}

.videos-list-left-gradient--active {
  opacity: 1;
}

#videos-list {
  padding-top: 38px;
  padding-bottom: 24px;
  margin-top: 32px;
  margin-bottom: 38px;
  position: relative;

  @screen lg {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  &:before {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      90deg,
      transparent 0%,
      white 50%,
      transparent 100%
    );

    @screen lg {
      display: none;
    }
  }

  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      90deg,
      transparent 0%,
      white 50%,
      transparent 100%
    );

    @screen lg {
      display: none;
    }
  }
}

.gradient-black {
  background-image: linear-gradient(
    180deg,
    transparent 0%,
    rgba(black, 0.7) 100%
  );
}

.check-mark {
  display: block;
  transform: rotate(-45deg);
  position: relative;
  top: -2px;
  left: 1px;

  &:after {
    content: '';
    display: block;
    width: 14px;
    height: 2px;
    background-color: white;
  }

  &:before {
    content: '';
    display: block;
    width: 2px;
    height: 5px;
    background-color: white;
  }
}

.container {
  padding: 0 18px;
  width: 100%;
  max-width: 1580px;
  margin: 0 auto;

  @screen sm {
    padding: 0 32px;
  }

  @screen smaller {
    padding: 0 40px;
  }

  @screen sm2 {
    padding: 0 60px;
  }

  @screen lg {
    width: 95%;
  }

  @screen 2xl {
    width: 90%;
  }
}

.video-item {
  @screen lg {
    img {
      transition: transform 0.3s ease;
      border-radius: 5px;
    }

    &:hover {
      img {
        border-radius: 5px;
        transform: scale(1.3);
        transition: all 0.3s ease;
      }
    }
  }
}

.icon-circle {
  display: flex;
  flex-shrink: 0;
  background-image: linear-gradient(241deg, #ebe2cb 0%, #d0bd91 100%);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  box-shadow: 0 0 0px 3px rgba(#d6c49d, 0.4);

  @screen md {
    width: 43px;
    height: 43px;
  }
}

.bleeding-32 {
  margin-left: -32px;
  margin-right: -32px;
}

.bleeding {
  margin-left: -28px;
  margin-right: -28px;

  padding-left: 28px;
  padding-right: 28px;

  @screen smaller {
    margin-left: -40px;
    margin-right: -40px;

    padding-left: 40px;
    padding-right: 40px;
  }

  @screen sm2 {
    margin-left: -60px;
    margin-right: -60px;

    padding-left: 60px;
    padding-right: 60px;
  }

  @screen md {
    margin-left: 0;
    margin-right: 0;

    padding-left: 0;
    padding-right: 0;
  }
}

.container--max-w-1260 {
  max-width: 1260px;
}

.container--sec {
  max-width: 1290px;

  @screen larger {
    width: 80%;
  }
}

.container--3 {
  max-width: 1290px;
  width: 100%;
}

.container--max-w-1024 {
  max-width: 1024px;

  @screen xxl {
    max-width: 1290px;
  }
}

.container--max-w-1160 {
  max-width: 1160px;
}

.container--max-w-980 {
  max-width: 980px;
}

.container--max-w-1111 {
  max-width: 1290px;

  /* @screen xxl {
    max-width: 1290px;
  } */
}

.container--third {
  max-width: 1290px;

  @screen 2xl {
    width: 80%;
  }
}

.container--pad-min {
  padding: 0 18px;
}

.trustpilot-widget {
  margin-top: 16px;
  transform: scale(0.8);
  transform-origin: left;

  @screen md {
    transform: none;
  }
}

.trustpilot--checkout {
  margin-top: 0;
  transform-origin: center;
}

.trustpilot--checkout-mob {
  margin-top: -3px;
  margin-bottom: 0 !important;
  transform: scale(0.7);
  transform-origin: left;
}

.trustpilot--home-page {
  margin-left: -9px;

  @screen md {
    margin-left: -13px;
  }
}

.border-top-white {
  border-top: 1px solid white;
}

.border-top-gray {
  border-top: 1px solid #333333;

  @screen lg {
    border-top: none;
  }
}

.border-right-gray {
  border-right: 1px solid #333333;
}

.border-left-gray {
  @screen lg {
    border-left: 1px solid #333333;
  }
}

.line-white {
  display: block;
  width: 95%;
  height: 1px;
  background-color: white;
}

.fake-nav {
  opacity: 0;
  height: 0;
}

.PlanCard_plan_card_referral {
  display: flex;
  color: black;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ededed;
  font-size: 11px;

  @screen xl {
    font-size: 13px;
  }
}

.PlanCard_plan_card_referral_img {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  background: #dee6ee;
  color: #3574a3;
  border-radius: 50%;
}

.link {
  color: $secondary;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.most-popular-label {
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #3f6bf3;
  width: 152px;
  padding: 5px 0;
  text-align: center;
  border-radius: 5px;
  font-weight: 600;
  box-shadow: 0 0 10px rgba(#3f6bf3, 0.6);
}

.most-popular-label-static {
  display: block;
  position: relative;
  top: 0;
}

.speak-to-coach-label {
  display: inline-block;
  background-color: #ba55ec;
  color: white;
  padding: 5px 10px;
  text-align: center;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  box-shadow: 0 0 10px rgba(#ba55ec, 0.6);
}

.hover-scale-border {
  transition: all 0.2s ease;
  outline: 0px;

  &:hover {
    transition: all 0.2s ease;
    transform: scale(1.03);
    outline: 2px solid $secondary;
  }
}

.active-scale-border {
  transform: scale(1.03);
  outline: 2px solid $secondary;
}

.text-shadow--green {
  text-shadow: 0 3px 10px #6fdf7c;
}

.plan_card_badge {
  border-radius: 4px;
  color: #f6f4ee;
  font-size: 10px;
  font-weight: 600;
  padding: 5px 7px;
  background-color: #bca36b;
  line-height: 1;

  @screen md {
    font-size: 14px;
  }
}

.sms-code-input {
  width: 100% !important;

  input {
    background-color: #262d36;
    color: #f6f4ee !important;
    font-family: Manrope !important;
    margin-right: 5px;
    border: 1px solid #636363 !important;
    border-radius: 4px !important;
    width: 40px !important;
    height: 40px !important;

    @screen md {
      margin-right: 10px;
    }

    &:focus {
      caret-color: #5ad082 !important;
    }
  }
}

.sms-code-input--light {
  input {
    background-color: white;
    color: black !important;
  }
}

.react-code-input {
  input {
    width: 53px !important;
    height: 53px !important;
    padding-left: 16px !important;
    background-color: #262d36 !important;
    color: #f6f4ee !important;
    border: 1px solid #636363 !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

@supports (-webkit-background-clip: text) or (background-clip: text) {
  .text-with-gradient {
    background: linear-gradient(
      89.95deg,
      #be55f0 0.04%,
      #2e6ef3 29.74%,
      #5ad082 58.42%
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
}

.testimonials-infinite-tape {
  margin-left: -28px;
  width: 100vw;
  overflow: visible !important;

  @screen larger {
    margin-left: 0;
    width: auto;
    overflow: hidden !important;
  }
}

.carousel .slider-wrapper {
  overflow: visible !important;
}

.carousel.carousel-slider {
  overflow: visible !important;
}

.color--gold {
  color: $secondary;
}

.color--green {
  color: $green;
}

.color--black {
  color: #19232f;
}

.bg--light-blue {
  background-color: #dde8ff;
}

.bg--primary {
  background-color: $primary;
}

.bg--white {
  background-color: #fbfbfb;
}

.bg--dark {
  background-color: #131b24;
}

#hubspot-messages-iframe-container {
  z-index: 99 !important;
}

// .Headline_headline__h1___k0ID {
//   // perspective: 5000px;
//   &:hover {
//     span {
//       transform: rotate3d(0, 1, 0, 0deg) translateX(0);
//       transition: transform 3s ease;
//     }
//   }
//   span {
//     display: inline-block;
//     transform: rotate3d(0, 1, 0, 90deg) translateX(-60px);
//     transition: transform 3s ease;
//   }
// }

.bg-decor {
  &:after {
    content: '';
    display: block;
    position: absolute;
    background-color: $primary;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 65%;
    z-index: -1;
  }
}

.dot {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: white;
}

.dot--black {
  background-color: black;
}

.logo {
  cursor: pointer;

  svg {
    margin: auto;
    transition: all 0.3s ease;
  }
}

.logo--primary {
  transform: scale(0.9);
  transform-origin: left;

  @screen xxl {
    transform: scale(1);
  }

  svg {
    margin-left: 0;
  }
}

.elite-switcher {
  background-color: #313942;
  border-radius: 30px !important;
  border: 4px solid #313942 !important;

  .switcher_indicator {
    background: #bda36b;
    border-radius: 30px;
    box-shadow: 0 0 6px #494949;
  }

  .switcher_label {
    color: rgba(#ffffff, 0.35);
  }
}

.elite-switcher-white {
  background-color: white;
  border-radius: 30px !important;
  border: 4px solid #f1f1f1 !important;
  box-shadow: inset 0 0 10px rgba(black, 0.1);

  .switcher_indicator {
    background: #bda36b;
    border-radius: 30px;
    box-shadow: none;
  }

  .switcher_label {
    color: #b5b5b5;
  }

  .switcher_label_active {
    color: white !important;
  }
}

.chevron {
  width: 8px;
  height: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.3s ease;

  &:after,
  &:before {
    content: '';
    display: block;
    width: 5px;
    height: 1px;
    background-color: white;
    position: absolute;
  }

  &:after {
    left: 0;
    transform: rotate(45deg);
  }

  &:before {
    right: 0;
    transform: rotate(-45deg);
  }
}

.chevron--black {
  &:after,
  &:before {
    background-color: black;
  }
}

.chevron--top {
  transform: rotate(180deg);
  transition: all 0.3s ease;
}

.chevron--big {
  width: 13px;
  height: 7px;

  &:after,
  &:before {
    width: 8px;
    height: 1px;
  }
}

.chevron--left {
  transform: rotate(90deg);
}

.button--secondary-white:hover .chevron {
  &:after,
  &:before {
    background-color: black;
  }
}

.react-toggle-track {
  background-color: #0b2549;
}

.react-toggle--checked .react-toggle-track {
  background-color: #325587;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #0b2549;
}

.page-404 {
  .site-footer {
    border-top: none;
    background-color: transparent;
  }
}

#coached-ritual {
  font-size: 13px;

  @screen sm {
    font-size: inherit;
  }
}

.badge {
  padding: 5px 12px;
  font-family: 'Manrope';
  font-style: normal;
  border-radius: 6px;
  background-color: #3e9b5e;
  color: white;
}

.dark-gradient {
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    content: '';
    display: block;
    width: 100%;
    height: 300px;
    pointer-events: none;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    );
  }
}

.blue-gradient-top {
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    width: 100%;
    height: 600px;
    pointer-events: none;
    background: linear-gradient(
      180deg,
      rgba($primary, 1) 0%,
      rgba($primary, 0) 100%
    );
  }
}

.testimonial-checkout {
  transition: all 0.2s ease;

  &:hover {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
    transition: all 0.2s ease;
  }
}

.blue-gradient-bottom {
  position: relative;

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    display: block;
    width: 100%;
    height: 600px;
    pointer-events: none;
    background: linear-gradient(
      0deg,
      rgba($primary, 1) 0%,
      rgba($primary, 0) 100%
    );
  }
}

.post-image {
  padding-top: clamp(300px, 25vw, 700px);
  background-size: cover;
  background-position: center;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0) 70%
    );
  }
}

.post {
  max-width: 800px;
  margin: clamp(30px, 6vw, 70px) auto;
  font-size: clamp(18px, 1.2vw, 20px);

  @screen md {
    width: 80%;
  }

  @screen lg {
    width: 70%;
  }

  p {
    margin-bottom: clamp(20px, 1.5vw, 30px);
    font-weight: 200;
  }

  ul,
  ol {
    margin-bottom: clamp(20px, 1.5vw, 30px);
  }

  li {
    display: flex;
    margin-left: 16px;
    margin-bottom: clamp(8px, 1.5vw, 12px);

    p {
      margin: 0;
    }

    &:before {
      content: '';
      display: block;
      flex-shrink: 0;
      width: 6px;
      height: 6px;
      background-color: $secondary;
      border-radius: 50%;
      margin-right: 10px;
      margin-top: clamp(10px, 1.5vw, 12px);
    }
  }

  img {
    margin-bottom: clamp(20px, 1.5vw, 30px);
  }

  h2 {
    margin: clamp(30px, 3vw, 50px) 0;
    font-size: clamp(24px, 2vw, 28px);
    font-family: Manrope;
    font-weight: 600;
    color: $secondary;
  }

  h3 {
    margin: clamp(30px, 3vw, 50px) 0;
    font-size: clamp(20px, 2vw, 23px);
    font-family: Manrope;
    font-weight: 600;
    color: $secondary;
  }

  blockquote {
    position: relative;
    padding-left: 20px;
    margin-bottom: clamp(20px, 1.5vw, 30px);
    font-style: italic;

    p {
      margin: 0;
    }

    &:before {
      content: '';
      display: block;
      background-color: $secondary;
      width: 3px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  a {
    text-decoration: underline;
    color: $secondary;

    &:hover {
      text-decoration: none;
    }
  }
}

.position-description {
  p {
    margin-bottom: clamp(20px, 1.5vw, 30px);
    font-weight: 200;
  }

  ul,
  ol {
    margin-bottom: clamp(20px, 1.5vw, 30px);
  }

  li {
    display: flex;
    margin-left: 16px;
    margin-bottom: clamp(8px, 1.5vw, 12px);

    p {
      margin: 0;
    }

    &:before {
      content: '';
      display: block;
      flex-shrink: 0;
      width: 6px;
      height: 6px;
      background-color: $secondary;
      border-radius: 50%;
      margin-right: 10px;
      margin-top: clamp(10px, 1.5vw, 12px);
    }
  }

  img {
    margin-bottom: clamp(20px, 1.5vw, 30px);
  }

  h2 {
    margin: clamp(30px, 3vw, 50px) 0;
    font-size: clamp(24px, 2vw, 28px);
    font-family: Manrope;
    font-weight: 600;
    color: $secondary;
  }

  h3 {
    margin: clamp(30px, 3vw, 50px) 0;
    font-size: clamp(20px, 2vw, 23px);
    font-family: Manrope;
    font-weight: 600;
    color: $secondary;
  }

  blockquote {
    position: relative;
    padding-left: 20px;
    margin-bottom: clamp(20px, 1.5vw, 30px);
    font-style: italic;

    p {
      margin: 0;
    }

    &:before {
      content: '';
      display: block;
      background-color: $secondary;
      width: 3px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  a {
    text-decoration: underline;
    color: $secondary;

    &:hover {
      text-decoration: none;
    }
  }
}

.filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($primary, 0.85);
}

.carousel-main {
  @screen md {
    transform: translateY(-50%);
    margin-bottom: -100px;
  }
}

.card--position {
  height: auto !important;
  margin-bottom: 28px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transform: none;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.02);
    transition: transform 0.3s ease;
  }

  &:after {
    content: '';
    display: block;
    background: radial-gradient(
      circle,
      #be55f0 0%,
      #446af3 33%,
      #2e61f3 39%,
      #5ad08240 50%,
      rgba(90, 208, 130, 0) 70%
    );
    opacity: 0.3;
    width: 100%;
    height: 500px;
    border-radius: 50%;
    position: absolute;
    bottom: -300px;
    left: 0;
    right: 0;
    margin: auto;
  }

  &:before {
    content: '';
    display: block;
    width: 260px;
    height: 260px;
    border-radius: 50%;
    position: absolute;
    bottom: -170px;
    left: 0;
    right: 0;
    margin: auto;
    border: 1px solid #bca36b;
    opacity: 0.5;
  }

  @screen md {
    margin: 24px !important;
  }

  @screen xl {
    margin: 16px !important;
  }
}

.masonry-grid {
  display: flex;
  width: auto;
  position: relative;
  z-index: 1;

  /* @screen md {
    margin-left: -20px;
    margin-right: -20px;
  }

  @screen lg {
    margin-left: -15px;
    margin-right: -15px;
  } */
}

.my-masonry-grid_column {
  padding: 30px; /* gutter size */
  background-clip: padding-box;
}

.page {
  max-width: 960px;
  font-size: clamp(18px, 1.2vw, 20px);
  margin: 0 auto;
  padding-top: clamp(220px, 15vw, 280px);

  @screen lg {
    width: 80%;
  }

  @screen xl {
    width: 70%;
  }

  p {
    margin-bottom: clamp(20px, 1.5vw, 30px);
    font-weight: 200;
  }

  ul,
  ol {
    margin-bottom: clamp(20px, 1.5vw, 30px);
  }

  li {
    display: flex;
    margin-left: 16px;
    margin-bottom: clamp(8px, 1.5vw, 12px);

    p {
      margin: 0;
    }

    &:before {
      content: '';
      display: block;
      flex-shrink: 0;
      width: 6px;
      height: 6px;
      background-color: $secondary;
      border-radius: 50%;
      margin-right: 10px;
      margin-top: clamp(10px, 1.5vw, 12px);
    }
  }

  img {
    margin-bottom: clamp(20px, 1.5vw, 30px);
  }

  h2 {
    margin: clamp(30px, 3vw, 50px) 0;
    font-size: clamp(24px, 2vw, 28px);
    font-family: Manrope;
    font-weight: 600;
    color: $secondary;
  }

  h3 {
    margin: clamp(30px, 3vw, 50px) 0;
    font-size: clamp(20px, 2vw, 23px);
    font-family: Manrope;
    font-weight: 600;
    color: $secondary;
  }

  h4 {
    margin: clamp(16px, 3vw, 26px) 0;
    font-size: clamp(16px, 2vw, 20px);
    font-family: Manrope;
    font-weight: 600;
    color: white;
  }

  blockquote {
    position: relative;
    padding-left: 20px;
    margin-bottom: clamp(20px, 1.5vw, 30px);
    font-style: italic;

    p {
      margin: 0;
    }

    &:before {
      content: '';
      display: block;
      background-color: $secondary;
      width: 3px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  a {
    text-decoration: underline;
    color: $secondary;

    &:hover {
      text-decoration: none;
    }
  }
}

.list {
  display: flex;
  margin-left: 16px;
  margin-bottom: clamp(8px, 1.5vw, 12px);

  &:before {
    content: '';
    display: block;
    flex-shrink: 0;
    width: 6px;
    height: 6px;
    background-color: $secondary;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: clamp(9px, 1.5vw, 10px);
  }
}

.list--red {
  &:before {
    margin-top: 7px;
    width: 10px;
    height: 10px;
    background-color: red;
  }
}

.list-checkmark {
  width: 16px;
  height: 12px;
  position: absolute;
  left: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(-45deg) scale(0.6);

  @screen lg {
    top: 5px;
    left: 10px;
    bottom: auto;
  }

  &:after {
    content: '';
    display: block;
    width: 18px;
    height: 10px;
    border-bottom: 4px solid #bda36b;
    border-left: 4px solid #bda36b;
  }
}

.list-checkmark--green {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #5acc80;
  border-radius: 50%;
  left: 5px;
  top: 5px;
  margin: auto;

  &:after {
    content: '';
    display: block;
    margin-top: -1px;
    width: 8px;
    height: 5px;
    border-bottom: 2px solid white;
    border-left: 2px solid white;
    transform: rotate(-45deg);
  }
}

.list--white {
  &:before {
    content: '';
    background-color: white;
  }
}

.list--larger {
  font-size: clamp(16px, 1.1vw, 19px);
}

.ol {
  font-size: clamp(18px, 1.5vw, 20px);
}

.list-ol {
  display: flex;
  margin-bottom: clamp(16px, 1.5vw, 20px);

  @screen xl {
    margin-left: 16px;
  }

  &:before {
    content: '';
    display: block;
    flex-shrink: 0;
    width: 6px;
    height: 6px;
    background-color: $secondary;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: clamp(10px, 1.5vw, 12px);
  }
}

.contact-info__item {
  display: flex;
  margin-bottom: 30px;
}

.contact-info__text {
  margin-left: 16px;
  margin-top: 3px;

  @screen md {
    margin-top: 0;
  }
}

.page-404-container {
  flex-direction: column;
  height: calc(100vh - 192px);
  align-items: center;
  justify-content: center;
}

.carousel_3d_next_control,
.carousel_3d_prev_control,
.checkout-testimonials-carousel_next_control,
.checkout-testimonials-carousel_prev_control,
.carousel_3d_next_control--sec,
.carousel_3d_prev_control--sec {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 36px;
  width: 25px;
  cursor: pointer;
  padding: 5px;
  transition: transform 0.3s ease;
  display: none;

  @screen lg {
    display: block;
  }

  &:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
    width: 18px;
    height: 2px;
    background: white;
    border-radius: 2px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
    width: 18px;
    height: 2px;
    background: white;
    border-radius: 2px;
  }
}

.carousel_3d_control--light {
  &:after,
  &:before {
    background: black;
  }
}

.bullets--dark {
  span {
    background-color: gray;
    box-shadow: none;
  }
}

.checkout-testimonials-carousel_next_control,
.checkout-testimonials-carousel_prev_control {
  @screen md {
    display: block;
  }
}

.home-page-carousel_next_control {
  top: auto;
  bottom: -55px;
  right: 80px !important;
  transform: scale(0.7);
}

.home-page-carousel_prev_control {
  top: auto;
  bottom: -55px;
  left: 80px !important;
  transform: scale(0.7);
}

.free-call-carousel_next_control {
  top: auto;
  bottom: -25px;
  right: 80px !important;
  transform: scale(0.7);
}

.free-call-bullets {
  margin-top: 0 !important;
}

.free-call-carousel_prev_control {
  top: auto;
  bottom: -25px;
  left: 80px !important;
  transform: scale(0.7);
}

.hp-prev-control {
  left: 44% !important;
}

.hp-next-control {
  right: 44% !important;
}

.carousel_3d_next_control,
.carousel_3d_next_control--sec {
  right: 0;

  @screen 2xl {
    right: 30px;
  }

  &:after {
    transform: rotate(45deg);
    right: 3px;
    transform-origin: 100% 100%;
  }

  &:before {
    transform: rotate(-45deg);
    right: 3px;
    transform-origin: 100% 0;
  }
}

.carousel_3d_prev_control,
.carousel_3d_prev_control--sec {
  left: 0;

  @screen 2xl {
    left: 30px;
  }

  &:after {
    transform: rotate(45deg);
    transform-origin: 0 0;
  }

  &:before {
    transform: rotate(-45deg);
    transform-origin: 0 100%;
  }
}

.carousel_3d_prev_control--sec {
  top: auto;
  left: 40%;
  bottom: -55px;
  transform: scale(0.6);

  &:hover {
    transform: scale(0.7);
  }
}

.carousel_3d_next_control--sec {
  top: auto;
  right: 40%;
  bottom: -55px;
  transform: scale(0.6);

  &:hover {
    transform: scale(0.7);
  }
}

.carousel_3d_control--sec-dark {
  &:after,
  &:before {
    background: black;
  }
}

.bullets-black {
  span {
    background-color: gray !important;
    box-shadow: none;
  }
}

.carousel_quiz_prev_control {
  left: 44%;
}

.carousel_quiz_next_control {
  right: 44%;
}

.checkout-testimonials-carousel_next_control {
  right: 0;

  @screen md {
    right: -60px;
  }

  &:after {
    transform: rotate(45deg);
    right: 3px;
    transform-origin: 100% 100%;
  }

  &:before {
    transform: rotate(-45deg);
    right: 3px;
    transform-origin: 100% 0;
  }
}

.checkout-testimonials-carousel_prev_control {
  left: 0;

  @screen md {
    left: -60px;
  }

  &:after {
    transform: rotate(45deg);
    transform-origin: 0 0;
  }

  &:before {
    transform: rotate(-45deg);
    transform-origin: 0 100%;
  }
}

.carousel-3d-container {
  margin: 0 auto;
  overflow: visible !important;

  @screen lg {
    width: 900px !important;
  }

  @screen 2xl {
    width: 1400px !important;
  }
}

.carousel-3d-container--sec {
  margin: 0 auto;
  overflow: visible !important;
}

.apply-form-upload-btn {
  margin-top: 16px;
}

.hover-underline-animation {
  position: relative;

  &:hover {
    &:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
}

.hover-underline-animation--black {
  &:after {
    background-color: black;
  }
}

.anim-shaking {
  animation: horizontal-shaking 0.6s 1;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.testimonials-carousel .simple_carousel_item {
  filter: blur(2px);

  @screen 2xl {
    filter: none;
  }

  transition: filter 0.3s ease;
}

.checkout-testimonials-carousel {
  margin-left: -16px;
  margin-right: -16px;
}

.hero-testimonials-carousel {
  margin-left: -8px;
  margin-right: -8px;
}

.testimonials-carousel .simple_carousel_item.simple-carousel-active {
  filter: blur(0);

  @screen 2xl {
    filter: none;
  }

  transition: filter 0.3s ease;
}

.modal__close-btn-tiny {
  top: 0 !important;
  right: 0 !important;
  transform: scale(0.8);
}

.checkout-testimonials-carousel-item {
  opacity: 0.7;
  transition: all 0.3s ease;
  touch-action: pan-x;

  &.simple-carousel-active {
    opacity: 1;
    transition: all 0.3s ease;
  }
}

.logos-carousel {
  opacity: 0.6;
  width: 400px;
  overflow: visible !important;

  @screen xl {
    width: 450px;
  }

  @screen 2xl {
    width: 550px;
  }

  @screen xxl {
    width: 600px;
  }

  @screen smaller {
    overflow: hidden !important;
  }
}

.logos-carousel--sec {
  width: 660px;

  @screen lg {
    width: 400px;
  }
}

.logos-carousel span:nth-child(2) {
  margin-top: 3px;
}

.logos-carousel span:nth-child(3) {
  margin-top: -3px;
}

.logos-carousel span:nth-child(5) {
  margin-top: 3px;
}

.logos-carousel span:nth-child(6) {
  margin-top: -3px;
}

.menu_wrapper.hovered div span:first-child {
  transform: translateY(2px);
  transition: all 0.3s ease;
}

.menu_wrapper.hovered div span:last-child {
  transform: translateY(-2px);
  transition: all 0.3s ease;
}

.menu_wrapper div span:first-child,
.menu_wrapper div span:last-child {
  transition: all 0.3s ease;
}

:root {
  --toastify-color-progress-dark: linear-gradient(
    89.95deg,
    #be55f0 0.04%,
    #2e6ef3 49.74%,
    #5ad082 98.42%
  ) !important;
}

.Toastify {
  position: relative;
  z-index: 99999999;
}

.headline3 {
  font-weight: 800;
  font-family: 'Manrope';
  color: #bca36b;
  line-height: 1.2;
}

.with-border-left {
  padding-left: 30px;
  border-left: 1px dashed #bda36a;
}

#hubspot-messages-iframe-container.widget-align-right {
  z-index: 999 !important;
}

.with-number-01,
.with-number-02 {
  position: relative;

  &:before {
    display: block;
    position: absolute;
    left: -40px;
    top: -55px;
    font-size: 140px;
    font-style: italic;
    font-weight: 200;
    color: white;
    opacity: 0.17;
  }
}

.with-number-01 {
  &:before {
    content: '01';
  }
}

.with-number-02 {
  &:before {
    content: '02';
  }
}

.text-46-to-60 {
  font-size: clamp(46px, 3.125vw, 60px);
}

.text-42-to-56 {
  font-size: clamp(42px, 2.9vw, 56px);
}

.text-42-to-60 {
  font-size: clamp(42px, 3.1vw, 60px);
}

.text-36-to-48 {
  font-size: clamp(34px, 2.2vw, 48px);
}

.text-36-to-70 {
  font-size: clamp(36px, 3.5vw, 70px);
}

.text-36-to-60 {
  font-size: clamp(36px, 3vw, 60px);
}

.text-24-to-30 {
  font-size: clamp(24px, 1.6vw, 30px);
}

.text-20-to-30 {
  font-size: clamp(20px, 1.6vw, 30px);
}

.text-28-to-48 {
  font-size: clamp(28px, 2.8vw, 48px);
}

.text-24-to-38 {
  font-size: clamp(24px, 3.125vw, 38px);
}

.text-24-to-28 {
  font-size: clamp(24px, 1.3vw, 28px);
}

.text-20-to-26 {
  font-size: clamp(20px, 1.3vw, 26px);
}

.text-23-to-25 {
  font-size: clamp(23px, 1.1vw, 25px);
}

.text-14-to-18 {
  font-size: clamp(14px, 1vw, 18px);
}

.text-15-to-18 {
  font-size: clamp(15px, 1vw, 18px);
}

.text-11-to-16 {
  font-size: clamp(11px, 1vw, 16px);
}

.text-18-to-20 {
  font-size: clamp(16px, 1.1vw, 19px);
  line-height: 1.35;
  font-weight: 200;
}

.text-17-to-20 {
  font-size: clamp(17px, 1.1vw, 20px);
}

.text-18-to-22 {
  font-size: clamp(18px, 1.2vw, 22px);
}

.text-22-to-29 {
  font-size: clamp(22px, 3.125vw, 29px);
}

.mb-70-to-100 {
  margin-bottom: clamp(70px, 5vw, 100px);
}

.mb-50-to-90 {
  margin-bottom: clamp(50px, 4vw, 90px);
}

.mb-10-to-50 {
  margin-bottom: clamp(10px, 2vw, 50px);
}

.mt-50-to-100 {
  margin-top: clamp(50px, 8vw, 100px);
}

.mt-30-to-90 {
  margin-top: clamp(30px, 4vw, 90px);
}

.mt-60-to-90 {
  margin-top: clamp(60px, 4vw, 90px);
}

.mt-40-to-60 {
  margin-top: clamp(40px, 3vw, 60px);
}

.mt-40-to-170 {
  margin-top: clamp(40px, 9vw, 170px);
}

.mt-40-to-100 {
  margin-top: clamp(40px, 6vw, 100px);
}

.mb-50-to-100 {
  margin-bottom: clamp(50px, 8vw, 100px);
}

.mb-30-to-40 {
  margin-bottom: clamp(30px, 2.2vw, 40px);
}

.mb-30-to-50 {
  margin-bottom: clamp(30px, 3.2vw, 50px);
}

.mt-20-to-40 {
  margin-top: clamp(20px, 1.5vw, 40px);
}

.mt-30-to-40 {
  margin-top: clamp(30px, 2.2vw, 40px);
}

.mb-30-to-60 {
  margin-bottom: clamp(30px, 10vw, 60px);
}

.mb-40-to-50 {
  margin-bottom: clamp(40px, 2.3vw, 50px);
}

.mb-16-to-20 {
  margin-bottom: clamp(16px, 1.5vw, 20px);
}

.mt-60-to-140 {
  margin-top: clamp(60px, 8vw, 140px);
}

.mt-60-to-80 {
  margin-top: clamp(60px, 8vw, 80px);
}

.mt-160-to-340 {
  margin-top: clamp(160px, 18vw, 340px);
}

.mt-160-to-220 {
  margin-top: clamp(160px, 18vw, 220px);
}

.mt-200-to-340 {
  margin-top: clamp(200px, 15vw, 340px);
}

.pb-100-to-200 {
  padding-bottom: clamp(100px, 10vw, 200px);
}

.lg-pb-180-to-300 {
  padding-bottom: clamp(70px, 10vw, 130px);

  @screen lg {
    padding-bottom: clamp(180px, 20vw, 300px);
  }
}

.pt-100-to-200 {
  padding-top: clamp(100px, 10vw, 200px);
}

.pt-120-to-200 {
  padding-top: clamp(120px, 10vw, 200px);
}

.pb-30-to-60 {
  padding-bottom: clamp(30px, 10vw, 60px);
}

.pb-70-to-130 {
  padding-bottom: clamp(70px, 10vw, 130px);
}

.pt-70-to-130 {
  padding-top: clamp(70px, 10vw, 130px);
}

.text-20-to-28 {
  font-size: clamp(20px, 3.125vw, 28px);
}

.text-20-to-26 {
  font-size: clamp(20px, 1.3vw, 26px);
}

.ReactModalPortal {
  position: relative;
  z-index: 9999;
}

.ReactModal__Content {
  max-width: 600px;
  position: absolute;
  padding: 30px;
  width: 100%;
  margin: auto;
  right: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  outline: none;
}

.testimonial-hero-anim-less {
  animation: none !important;
}

.video-button .camera-icon path {
  fill: #bca36b;
  transition: all 0.3s ease;
}

.video-button:hover .camera-icon path {
  fill: $primary;
  transition: all 0.3s ease;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.perspective-1000 {
  perspective: 1000px;
}

.hover-rotate-right {
  transform-origin: left;
  transition: transform 0.3s ease;

  &:hover {
    transform: rotate3d(0, 1, 0, -30deg);
    transition: transform 0.3s ease;
  }
}

.hover-rotate-left {
  transform-origin: right;
  transition: transform 0.3s ease;

  &:hover {
    transform: rotate3d(0, 1, 0, 30deg);
    transition: transform 0.3s ease;
  }
}

.blurred-box {
  display: inline-block;
  padding: 10px 16px;
  border: 1px solid rgba(white, 0.4);
  border-radius: 10px;
  backdrop-filter: blur(16px);
  background-color: rgba(255, 255, 255, 0.05);
}

.blurred-box--dark {
  background-color: rgba(0, 0, 0, 0.2);
}

.video-testim-hover {
  .video-testim-play {
    transition: all 0.3s ease;
  }
  .video-testim-caption {
    transform: translateY(0);
    transition: all 0.3s ease;
  }

  &:hover {
    .video-testim-play {
      transform: scale(1.1);
      transition: all 0.3s ease;
    }
    .video-testim-caption {
      transform: translateY(-3px);
      transition: all 0.3s ease;
    }
  }
}

.modal-without-border {
  .modal-container {
    padding: 0;
    background-color: transparent;
    margin: 10px;
  }
  .modal__close-btn {
    background-color: white;
    border-radius: 50%;
    position: fixed !important;
    top: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
  }
}

.offcanvas-training {
  .offcanvas-body {
    &:before {
      display: none;
    }
  }
}
