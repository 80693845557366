.accordion_new {
}

.accordion_new__item {
  overflow: hidden;
}

.accordion_new__body {
  transition: all 0.3s ease;
}

.accordion_new__title {
  cursor: pointer;
}
