.mobile_menu {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.mobile_menu_active {
  opacity: 1;
  visibility: visible;
  background-color: rgba(black, 0.6);
  backdrop-filter: blur(8px);
  transition: all 0.3s ease-in-out;
}

.mobile_menu_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 60px;
}

.mobile_menu_ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}
