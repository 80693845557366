.progress-button-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #5ad082;
  opacity: 0;
}

.progress-button-container {
  height: clamp(45px, 3vw, 54px);
}

.progress-button-el {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform-origin: left;
  transform: scaleX(0);
  background-color: #3aa15d;
  opacity: 0;
}

.progress-button-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  pointer-events: none;
  top: 16px;
  width: 100%;
}

.progress-button-text {
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-5px);
  transition: all 0.4s ease;
}

.progress-button-text-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 0.4s ease;
}
