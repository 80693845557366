.slider_on_scroll_item {
  width: 100%;
  visibility: hidden;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;

  &:last-child {
    cursor: default;
  }
}

.slider_on_scroll_item-appear,
.slider_on_scroll_item-appear-done {
  visibility: visible;
}

.slider_on_scroll_item-enter {
  visibility: visible;
  opacity: 0;
}

.slider_on_scroll_item-enter-active {
  opacity: 1;
  transition: all 2s ease;
}

.slider_on_scroll_item-exit {
  visibility: visible;
  opacity: 1;
}

.slider_on_scroll_item-exit-active {
  opacity: 0;
  transition: opacity 1s ease;
}

.slider_on_scroll_item-enter-done {
  visibility: visible;
}
