.headline2 {
  font-weight: 800;
  font-family: 'Manrope';
  color: #bca36b;
  line-height: 1.2;
}

.headline2__font_primary {
  font-family: 'Spectral';
}

.headline2_color_primary {
  color: #19232f;
}

.headline2_color_secondary {
  color: #bca36b;
}

.headline2_color_white {
  color: #f6f4ee;
}

.headline2_color_black {
  color: #19232f;
}

.headline2_weight_200 {
  font-weight: 200;
}

.headline2_weight_400 {
  font-weight: 400;
}

.headline2_weight_500 {
  font-weight: 500;
}

.headline2_weight_600 {
  font-weight: 600;
}

.headline2_weight_700 {
  font-weight: 700;
}

.headline2_weight_800 {
  font-weight: 800;
}
