.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal.show .modal-dialog {
  opacity: 1;
  transition: all 0.3s ease-out;
}

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 6px;
  pointer-events: none;
  max-width: 570px;
  transform: translateY(-50%);
  transition: all 0.2s ease-out;
  opacity: 0;

  @screen sm2 {
    margin: auto;
  }
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #19232f;
  outline: 0;
  padding: 28px;
}

.modal-content--white {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}

/* .overlay-black {
  background-color: black;
} */

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(black, 0.6);
  backdrop-filter: blur(8px);

  &.fade {
    opacity: 0;
    transition: all 0.2s ease-out;
  }

  &.show {
    opacity: 1;
    transition: all 0.3s ease-out;
  }
}

.modal__close-btn {
  position: absolute !important;
  right: 10px;
  top: 10px;

  @screen md {
    right: 30px;
    top: 30px;
  }
}

.modal__close-btn-sec {
  right: 10px;
  top: 10px;
}

.modal__close-btn-3 {
  right: 10px;
  top: 10px;
  transform: scale(0.8);

  @screen md {
    right: 20px;
    top: 20px;
  }
}

.modal__close-btn-small {
  right: -5px;
  top: -5px;
  transform: scale(0.8);
}
