@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-toggle/style.css';

@import 'utils/variables';
@import 'utils/functions';
@import 'utils/mixins';
@import 'utils/base';

@import 'components/controls/ButtonWithProgress.scss';
@import 'components/controls/Button.scss';
@import 'components/controls/Select.scss';
@import 'components/controls/PhoneControl.scss';

@import 'components/common/Offcanvas.scss';
@import 'components/common/Headline2.scss';

@import 'components/sections/HowItWorksSection.scss';

@import 'components/layout/Footer.scss';
@import 'components/layout/Nav.scss';

@import 'components/modal/Modal.scss';

@import 'components/common/Tabs.scss';

@import 'animations/FadeUp.scss';
